import { useContext, useState } from "react";
import { BASE_API_URL, userContext } from "../App";
import { useHistory } from "react-router-dom";
import { generateKeyPair } from "../functions/crypto";

const Login = () => {
    const [dataContext, setDataContext] = useContext(userContext);
    const [loginDto, setLoginDto] = useState({
        email: "",
        password: ""
    });

    const history = useHistory();
    const handleLogin = () => {
        const keyPair = generateKeyPair();

        fetch(`${BASE_API_URL}/teacher/login`, {
            method: 'POST',
            body: JSON.stringify(loginDto),
            headers: {
                'Content-Type': 'application/json',
                // Other headers if needed
            },
        })
            .then(res => res.json())
            .then(data => {
                localStorage.setItem('userData', JSON.stringify({ ...data, privateKey: keyPair.privateKey, publicKey: keyPair.publicKey }));
                setDataContext({
                    ...dataContext,
                    token: data.access_token,
                    user: data.data,
                    privateKey: keyPair.privateKey,
                    publicKey: keyPair.publicKey
                });
                // update public key
                fetch(`${BASE_API_URL}/teacher/${data.data?._id}`, {
                    method: 'PATCH',
                    body: JSON.stringify({ public_key: keyPair.publicKey }),
                    headers: {
                        'Content-Type': 'application/json',
                        // Other headers if needed
                    },
                })
                    .then(res => res.json())
                    .then(updatedData => {
                        if (updatedData) {
                            history.push('/');
                        }
                    })
            })
            .catch(err => console.log(err));
    }
    return (
        <div className="container">
            <div className="row justify-content-center align-items-center" style={{ minHeight: "80vh" }}>
                <div className="col-md-5 border bg-light rounded p-3">
                    <input type="email" className="form-control my-2" onChange={(e) => setLoginDto({ ...loginDto, email: e.target.value })} value={loginDto.email} placeholder="Enter Email" />
                    <input type="password" className="form-control my-2" onChange={(e) => setLoginDto({ ...loginDto, password: e.target.value })} value={loginDto.password} placeholder="Enter Password" />

                    <div className="text-center pt-2">
                        <button className="btn btn-sm btn-success" onClick={handleLogin}>Login</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;