import { createContext, useEffect, useState } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Home from './pages/Home';
import Login from './pages/Login';
import Cryptography from './pages/Criptography';

// export const BASE_API_URL = 'http://localhost:3001';
export const BASE_API_URL = 'http://103.28.121.117:3001';
export const userContext = createContext();

function App() {

  const userDataString = localStorage.getItem('userData');
  const userData = userDataString ? JSON.parse(userDataString) : null;
  const [dataContext, setDataContext] = useState({ 
    token: userData?.access_token || "",
    user: userData?.data || {},
    publicKey: userData?.publicKey,
    privateKey: userData?.privateKey 
  });

  return (

    <userContext.Provider value={[dataContext, setDataContext]}>
      <Router>
        <div>
          <Switch>
            <Route path="/login">
              {!dataContext.token ? <Login /> : <Home />}
            </Route>
            <Route path="/crypto">
              <Cryptography />
            </Route>
            <Route path="/">
              {dataContext.token ? <Home /> : <Login />}
            </Route>
          </Switch>
        </div>
      </Router>
    </userContext.Provider>
  );
}

export default App;
