import forge from 'node-forge';

// generate key pair
export const generateKeyPair = () => {
    const rsa = forge.pki.rsa;
    const keyPair = rsa.generateKeyPair({ bits: 2048, e: 0x10001 });
    const publicKey = forge.pki.publicKeyToPem(keyPair.publicKey);
    const privateKey = forge.pki.privateKeyToPem(keyPair.privateKey);

    return { privateKey, publicKey };
};

// encryption
export const encrypt = (publicKey, message) => {
    const publicKeyPem = forge.pki.publicKeyFromPem(String(publicKey));
    const encryptedMessage = publicKeyPem.encrypt(message, 'RSA-OAEP');

    return forge.util.encode64(encryptedMessage);
};

// decryption
export const decrypt = (privateKey, encryptedMessage) => {
    try {
        const privateKeyPem = forge.pki.privateKeyFromPem(String(privateKey));
        const encryptedMessageDecode = forge.util.decode64(encryptedMessage);
        const decryptedMessage = privateKeyPem.decrypt(encryptedMessageDecode, 'RSA-OAEP');

        return decryptedMessage;
    } catch (error) {
        return <span className='text-danger'>Something went wrong! Key mismatched! You maybe lost the message.</span>
    }
};