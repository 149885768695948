import React, { useState } from 'react';
import forge from 'node-forge';


const Cryptography = () => {
  const [message, setMessage] = useState('Hello, World!');
  const [publicKey, setPublicKey] = useState('');
  const [privateKey, setPrivateKey] = useState('');
  const [encryptedMessage, setEncryptedMessage] = useState('');
  const [decryptedMessage, setDecryptedMessage] = useState('');

  const handleGenerateKeyPair = () => {
    const keyPair = generateKeyPair();
    setPublicKey(forge.pki.publicKeyToPem(keyPair.publicKey));
    setPrivateKey(forge.pki.privateKeyToPem(keyPair.privateKey));
  };

  console.log({publicKey, privateKey})
  const handleEncrypt = () => {
    // Ensure publicKey is declared before using it
    const publicKeyPem = forge.pki.publicKeyFromPem(publicKey);
    const encryptedMessage = publicKeyPem.encrypt(message, 'RSA-OAEP');
    setEncryptedMessage(forge.util.encode64(encryptedMessage));
  };

  const handleDecrypt = () => {
    const privateKeyPem = forge.pki.privateKeyFromPem(privateKey);
    const encryptedMessageDecode = forge.util.decode64(encryptedMessage);
    const decryptedMessage = privateKeyPem.decrypt(encryptedMessageDecode, 'RSA-OAEP');
    setDecryptedMessage(decryptedMessage);
  };

  const generateKeyPair = () => {
    const rsa = forge.pki.rsa;
    return rsa.generateKeyPair({ bits: 2048, e: 0x10001 });
  };

  return (
    <div>
      <button onClick={handleGenerateKeyPair}>Generate Key Pair</button>
      <br />
      <textarea value={message} onChange={(e) => setMessage(e.target.value)} />
      <br />
      <button onClick={handleEncrypt}>Encrypt</button>
      <br />
      <textarea value={encryptedMessage} readOnly />
      <br />
      <button onClick={handleDecrypt}>Decrypt</button>
      <br />
      <textarea value={decryptedMessage} readOnly />
    </div>
  );
};

export default Cryptography;
