import { useContext, useEffect, useRef, useState } from 'react';
import { BASE_API_URL, userContext } from '../App';
import { decrypt, encrypt } from '../functions/crypto';
import io from 'socket.io-client';


function Home() {
    const [dataContext, setDataContext] = useContext(userContext);
    const [users, setUsers] = useState([]);
    const [chats, setChats] = useState([]);
    const [selectChat, setSelectChat] = useState('');
    const [chat, setChat] = useState({
        message: ''
    });
    const chatContainerRef = useRef(null);

    useEffect(() => {
        const socket = io(BASE_API_URL, {
            auth: {
                token: dataContext.token
            },
        });

        socket.on('activeUser', (data) => {
            console.log(data);
        });

        // Listen for the 'message' event from the server
        socket.on('message', (message) => {
            const decryptedMsg = decrypt(dataContext.privateKey, message.message);
            setChats((prevMessages) => [...prevMessages, { ...message, message: decryptedMsg }]);
        });

        // Clean up the socket connection when the component unmounts
        return () => {
            socket.disconnect();
        };
    }, []);

    useEffect(() => {

        fetch(`${BASE_API_URL}/teacher`)
            .then(res => res.json())
            .then(data => {
                setUsers(data?.data?.filter(user => user._id != dataContext.user?._id));
            })
            .catch(err => console.log(err));
    }, [])

    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [chats]);

    useEffect(() => {
        fetch(`${BASE_API_URL}/chat?from=${dataContext.user?._id}&to=${selectChat?._id}&page=1&limit=100`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${dataContext.token}`,
                'Content-Type': 'application/json',
                // Other headers if needed
            },
        })
            .then(res => res.json())
            .then(data => {
                // setChats(data?.data);
                const messages = data?.data?.reverse();
                const decryptedChats = messages?.map(message => {
                    if (message.from == dataContext.user?._id) {
                        const decryptedMsg = decrypt(dataContext.privateKey, message.message_from);

                        return { ...message, message: decryptedMsg };
                    } else {
                        const decryptedMsg = decrypt(dataContext.privateKey, message.message);

                        return { ...message, message: decryptedMsg };
                    }
                });

                setChats(decryptedChats);
            })
            .catch(err => {
                console.log(err);
            });
    }, [selectChat]);

    // send message
    const sendMessage = (e) => {
        e.preventDefault();

        const message = encrypt(selectChat.public_key, chat.message);
        const message_from = encrypt(dataContext.publicKey, chat.message);

        const body = { message, message_from, to: selectChat?._id };

        fetch(`${BASE_API_URL}/chat`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Authorization': `Bearer ${dataContext.token}`,
                'Content-Type': 'application/json',
                // Other headers if needed
            },
        })
            .then(res => res.json())
            .then(data => {
                setChats([...chats, { ...data?.data, message: chat.message }]);
                setChat({ ...chat, message: "" });
            })
            .catch(err => console.log(err));
    }

    return (
        <section className='container-lg'>
            <div className='row' style={{ minHeight: "100vh" }}>
                <div className='col-3 p-0'>
                    <div className='card'>

                        <div className="card-header bg-success text-white">
                            <h3 className='fw-bolder text-center'>Users</h3>
                        </div>
                        <div className="card-body p-0" style={{ height: "92vh", overflowY: 'auto' }}>
                            {
                                users?.map(user => (
                                    <h6 key={user._id} onClick={() => { setSelectChat(user) }} style={{ cursor: "pointer" }} className='bg-light py-4 shadow-sm fs-6 px-2 border-bottom m-0'>{user.first_name} {user.last_name}</h6>
                                ))
                            }
                        </div>
                    </div>
                </div>
                <div className='col-9 p-0'>
                    {!selectChat && <div className='fs-2 fw-bold d-flex justify-content-center align-items-center h-100'>Welcome {dataContext.user.first_name} {dataContext.user.last_name}</div>}
                    {selectChat && <div className='card'>

                        <div className="card-header shadow bg-success text-white">
                            <h3 className='fw-bolder text-center'>{selectChat.first_name} {selectChat.last_name}</h3>
                        </div>

                        <div className="card-body" ref={chatContainerRef} style={{ maxHeight: "83vh", overflowY: 'auto' }}>

                            <section>
                                {
                                    chats?.map(chat => (
                                        <div className={`${chat.from == dataContext.user._id ? 'text-end' : 'text-start'}`}>
                                            <span key={chat._id} style={{ maxWidth: '70%', wordWrap: "break-word" }} className={`d-inline-block border rounded py-2 px-3 my-1 ${chat.from == dataContext.user._id ? 'bg-success text-white' : 'bg-light'}`}>
                                                <div>{chat.message}</div>
                                                {/* <div><small>{chat.createdAt}</small></div> */}
                                            </span><br />
                                        </div>
                                    ))
                                }
                            </section>
                        </div>

                        <div className="card-footer border-none shadow">

                            <form className='d-flex' onSubmit={sendMessage}>
                                <input type='text' className='form-control shadow-sm py-3' id='input' value={chat.message} onChange={(e) => { setChat({ ...chat, message: e.target.value }) }} placeholder='enter message' />
                                <button className='ms-1 btn btn-sm btn-secondary px-3 fw-bolder' type='submit'>Send</button>
                            </form>
                        </div>
                    </div>
                    }
                </div>
            </div>
        </section>
    );
}

export default Home;
